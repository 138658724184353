var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tooltip
    ? _c(
        "span",
        { staticClass: "p-0 my-0 tool-tip" },
        [
          _c(
            "CButton",
            {
              directives: [
                {
                  name: "c-tooltip",
                  rawName: "v-c-tooltip",
                  value: _vm.tooltipData,
                  expression: "tooltipData"
                }
              ],
              staticClass: "p-0 my-0 px-2",
              attrs: { variant: "ghost" }
            },
            [
              _c("CIcon", {
                staticClass: "tool-tip",
                attrs: { name: _vm.icon, size: "sm" }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }