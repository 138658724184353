

<template>
  <div>
    <span class="frm-label">{{ label }} </span>
    <span v-if="required" class="text-danger">{{ requiredMessage }}</span>
    <CTooltipButton class="float-right" :tooltip="tooltip" placement="left" />
  </div>
</template>


<script>
import CTooltipButton from "./CTooltipButton";
import CFormInputBase from "./CFormInputBase";  
export default {
  name: "CFormLabel",
  extends:CFormInputBase,
  components:{CTooltipButton},
  props: {
      /*
    label: String,
    isValid: {
      type: Boolean,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredMessage: {
      type: String,
      default: "*",
    },
    tooltip: { type: String | Object },
    */
  },
  data() {
    return {};
  },
  
};
</script>