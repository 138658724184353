<template>
  <span v-if="tooltip" class="p-0 my-0 tool-tip" > 
      <CButton class="p-0 my-0 px-2"
      variant="ghost"
       v-c-tooltip="tooltipData"
     >
     <CIcon class="tool-tip" :name="icon" size="sm" />
      </CButton>
    
    </span>  
</template>


<script>
export default {
    name:"CTooltipButton",
    props:{
      tooltip: String, 
      placement:{
        type: String,
        default: "left"
      },
      icon:{
        type: String| Object,
        default: "cil-info"
      }
    }, 
    computed:{
      tooltipData(){
        return {
        content: this.tooltip,
        placement: this.placement
      }
      }
    }
    
}
</script>

<style scoped>
.tool-tip{
  text-align: left;

}
.tool-tip-text{
  text-align: left;

}
</style>