var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "frm-label" }, [
        _vm._v(_vm._s(_vm.label) + " ")
      ]),
      _vm.required
        ? _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.requiredMessage))
          ])
        : _vm._e(),
      _c("CTooltipButton", {
        staticClass: "float-right",
        attrs: { tooltip: _vm.tooltip, placement: "left" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }